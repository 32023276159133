import { Reservations } from '@lib/state/api';
import { from } from 'rxjs';

import { map } from 'rxjs/operators';

import { saveAs } from 'file-saver';

import { dispatchForm, MediaTypes, CommonForm, PaginationState } from '@lib/state';
import { BillingFrequencyType, CorporateAccount } from '../corporate-account';
import { CorporateInvoice, InvoiceType } from './corporate-invoice.model';
import { corporateInvoiceStore, CorporateInvoiceStore } from './corporate-invoice.store';
import { CorporateInvoiceForms } from './corporate-invoice.forms';
import { invoiceRunStore, InvoiceRunStore } from '../invoice-run';
import { applyTransaction } from '@datorama/akita';

export class CorporateInvoiceService {
  constructor(
    private readonly irStore: InvoiceRunStore,
    private readonly store: CorporateInvoiceStore,
    private readonly corporateAccountApi: Reservations.CorporateAccountApi
  ) {}

  getInvoices(
    corporateAccountId: CorporateAccount['id'],
    continuationToken?: string | null,
    limit?: number
  ) {
    from(
      this.corporateAccountApi.corporateaccountIdInvoiceGet(
        corporateAccountId,
        continuationToken ?? undefined,
        limit
      )
    )
      .pipe(map(response => response.data))
      .subscribe(({ data, ...pagination }) => {
        this.store.upsertMany(data);
        this.updatePaginationState(pagination);
      });
  }

  createInvoice(
    corporateAccountId: CorporateAccount['id'],
    startDate: string,
    endDate: string,
    type: InvoiceType,
    isDraft = false,
    billingFrequencyType?: BillingFrequencyType
  ) {
    from(
      this.corporateAccountApi.corporateaccountInvoicePost({
        corporateAccounts: [corporateAccountId],
        startDate,
        endDate,
        isDraft,
        type,
        billingFrequencyType,
      })
    )
      .pipe(
        map(({ data }) => data.data),
        dispatchForm(CorporateInvoiceForms.Create)
      )
      .subscribe(x =>
        applyTransaction(() => this.irStore.upsert(x.id, x), this.irStore.setActive(x.id))
      );
  }

  createMultipleInvoices(
    corporateAccounts: CorporateAccount[],
    startDate: string,
    endDate: string,
    type: InvoiceType,
    isDraft = false,
    billingFrequencyType?: BillingFrequencyType
  ) {
    from(
      this.corporateAccountApi.corporateaccountInvoicePost({
        corporateAccounts: corporateAccounts.map(a => a.id),
        startDate,
        endDate,
        isDraft,
        type,
        billingFrequencyType,
      })
    )
      .pipe(
        map(({ data }) => data.data),
        dispatchForm(CorporateInvoiceForms.Create)
      )
      .subscribe(x =>
        applyTransaction(() => this.irStore.upsert(x.id, x), this.irStore.setActive(x.id))
      );
  }

  removeInvoice({ id, corporateAccountId }: CorporateInvoice) {
    from(this.corporateAccountApi.corporateaccountIdInvoiceInvoiceIdDelete(corporateAccountId, id))
      .pipe(dispatchForm(CorporateInvoiceForms.Remove))
      .subscribe(() => this.store.remove(id));
  }

  resendInvoice({ id, corporateAccountId }: CorporateInvoice) {
    from(
      this.corporateAccountApi.corporateaccountIdInvoiceInvoiceIdResendGet(corporateAccountId, id)
    )
      .pipe(dispatchForm(CorporateInvoiceForms.Resend))
      .subscribe();
  }

  downloadInvoice({ id, corporateAccountId, invoiceNumber }: CorporateInvoice) {
    from(
      this.corporateAccountApi.corporateaccountIdInvoiceInvoiceIdGet(corporateAccountId, id, {
        responseType: 'blob',
      })
    )
      .pipe(
        map(x => new Blob([x.data], { type: MediaTypes.ExcelFile })),
        dispatchForm(CommonForm.Export)
      )
      .subscribe(x => saveAs(x, `${invoiceNumber}.xlsx`));
  }

  private updatePaginationState({ isDone, continuationToken }: PaginationState) {
    this.store.update(() => ({
      pagination: { isDone, continuationToken },
    }));
  }
}

export const corporateInvoiceService = new CorporateInvoiceService(
  invoiceRunStore,
  corporateInvoiceStore,
  new Reservations.CorporateAccountApi()
);
