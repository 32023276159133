import React, { useCallback, useMemo, useState } from 'react';

import { propertyQuery } from '@lib/state';
import { useObservable, usePageTitle, Section, AppColors } from '@lib/common';
import {
  corporateAccountQuery,
  corporateAccountService,
  GetInvoiceRunRequest,
  invoiceRunService,
  InvoiceStatusFilter,
} from 'app/state';
import {
  CorporateAccountLookup,
  CorporateAccountFilters,
  InvoiceRunFilterForm,
  InvoiceRunList,
} from '../components';
import { useGetInvoiceRuns, useGetInvoiceStatuses } from '../hooks';
import { TabContext, TabPanel } from '@material-ui/lab';
import { Box, Tab, Tabs } from '@material-ui/core';
import { Tile } from 'app/shared';

export const CorporateAccountSearchPage: React.FC = () => {
  const customerId = useObservable(propertyQuery.activeProperty)?.customerId;
  const accounts = useObservable(corporateAccountQuery.searchResults);
  const [runFilters, setRunFilters] = useState<GetInvoiceRunRequest>({ isDraft: false });
  const statusFilters = useMemo<InvoiceStatusFilter>(() => ({ customerId }), [customerId]);
  const { runs, loading: runsLoading, isDone, continuationToken } = useGetInvoiceRuns(runFilters);
  const { statuses } = useGetInvoiceStatuses(statusFilters);

  const filters = useObservable(corporateAccountQuery.filters);
  const pagination = useObservable(corporateAccountQuery.pagination);
  const [tab, setTab] = useState('accountList');

  usePageTitle('Corporate Accounts');

  const onChange = useCallback(
    (filters: CorporateAccountFilters) =>
      customerId &&
      corporateAccountService.getCorporateAccounts(customerId, {
        ...filters,
      }),
    [customerId]
  );

  const loadMore = useCallback(
    () =>
      customerId &&
      corporateAccountService.getCorporateAccounts(
        customerId,
        { ...filters },
        pagination.continuationToken
      ),
    [customerId, filters, pagination]
  );

  const loadMoreRuns = useCallback(
    () => continuationToken && invoiceRunService.getInvoiceRuns(runFilters, { continuationToken }),
    [runFilters, continuationToken]
  );

  return (
    <Section style={{ marginTop: '-1rem' }}>
      <TabContext value={tab}>
        <Tabs
          value={tab}
          onChange={(_, x) => setTab(x)}
          indicatorColor="primary"
          textColor="primary"
          style={{ backgroundColor: AppColors.LighterGray }}
        >
          <Tab label="Corporate Accounts" value="accountList" />
          <Tab label="Invoice Runs" value="runList" />
        </Tabs>
        <TabPanel value="accountList" style={{ padding: 0 }}>
          <Box p={3}>
            <CorporateAccountLookup
              accounts={accounts}
              invoiceStatuses={statuses}
              onChange={onChange}
              filters={filters}
              isDone={pagination.isDone}
              loadMore={loadMore}
            />
          </Box>
        </TabPanel>
        <TabPanel value="runList" style={{ padding: '.5rem' }}>
          <Tile>
            <InvoiceRunFilterForm onChange={setRunFilters} />
            <InvoiceRunList
              runs={runs}
              loading={runsLoading}
              isDone={isDone}
              loadMore={loadMoreRuns}
            />
          </Tile>
        </TabPanel>
      </TabContext>
    </Section>
  );
};
