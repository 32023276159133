import { CorporateAccountApi } from '@lib/state/api/generated/reservations';
import { invoiceStatusStore, InvoiceStatusStore } from './invoice-status.store';
import { InvoiceStatusFilter } from './invoice-status.model';
import { defer, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { applyTransaction, setLoading } from '@datorama/akita';
import { pollUntil, unsubscribeCallback } from '@lib/state';

export class InvoiceStatusService {
  constructor(private api: CorporateAccountApi, private store: InvoiceStatusStore) {}

  pollInvoiceStatuses(
    {
      createdStartDate,
      createdEndDate,
      status,
      invoiceRunId,
      corporateAccountId,
      customerId,
      sortBy,
    }: InvoiceStatusFilter,
    continuePolling: boolean = false,
    interval: number = 5000
  ) {
    const sub = defer(() =>
      this.api.corporateaccountInvoiceStatusesGet(
        createdStartDate?.toString(),
        createdEndDate?.toString(),
        status,
        invoiceRunId,
        corporateAccountId,
        customerId
      )
    )
      .pipe(
        map(data => data.data.data ?? []),
        pollUntil(interval, continuePolling)
      )
      .subscribe(s =>
        applyTransaction(
          () => this.store.set(s),
          this.updateUIState({
            createdStartDate,
            createdEndDate,
            status,
            invoiceRunId,
            corporateAccountId,
            sortBy,
          })
        )
      );

    return unsubscribeCallback(sub);
  }

  getInvoiceStatuses({
    createdStartDate,
    createdEndDate,
    status,
    invoiceRunId,
    corporateAccountId,
    customerId,
    sortBy,
  }: InvoiceStatusFilter) {
    from(
      this.api.corporateaccountInvoiceStatusesGet(
        createdStartDate?.toString(),
        createdEndDate?.toString(),
        status,
        invoiceRunId,
        corporateAccountId,
        customerId
      )
    )
      .pipe(
        map(data => data.data.data ?? []),
        setLoading(this.store)
      )
      .subscribe(s =>
        applyTransaction(
          () => this.store.set(s),
          this.updateUIState({
            createdStartDate,
            createdEndDate,
            status,
            invoiceRunId,
            corporateAccountId,
            sortBy,
          })
        )
      );
  }

  private updateUIState(ui: InvoiceStatusFilter) {
    this.store.update(() => ({
      ui,
    }));
  }
}

export const invoiceStatusService = new InvoiceStatusService(
  new CorporateAccountApi(),
  invoiceStatusStore
);
