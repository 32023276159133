import {
  ContactInformationForm,
  EnumValueDisplay,
  ErrorDisplay,
  FormHub,
  FormSelect,
  SubmitButton,
} from '@lib/common';
import { FormStatus } from '@lib/state';
import { CorporateContactType } from '@lib/state/api/generated/reservations';
import { Grid, MenuItem } from '@material-ui/core';
import { CorporateContactModel } from 'app/state';
import React, { useMemo } from 'react';

interface Props {
  contact?: CorporateContactModel;
  onSubmit: (contact: CorporateContactModel) => void;
  status: FormStatus;
  error?: Error;
}

export default function CorporateContactForm({
  contact,
  onSubmit,
  status,
  error,
}: Readonly<Props>) {
  const defaultValues = useMemo(
    () => ({
      contact: {
        email: contact?.contact?.email ?? undefined,
        phone: contact?.contact?.phone ?? undefined,
      },
      corporateContactType:
        CorporateContactType[contact?.corporateContactType as keyof typeof CorporateContactType] ??
        undefined,
    }),
    [contact]
  );

  return (
    <FormHub onSubmit={onSubmit} formOptions={{ defaultValues }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ContactInformationForm
            email={contact?.contact?.email}
            phone={contact?.contact?.phone}
            required="email"
          />
        </Grid>
        <Grid item xs={12}>
          <FormSelect
            label="Contact Type"
            name="corporateContactType"
            data-testid="formStateSelect"
            fullWidth
            required
            defaultValue={contact?.corporateContactType ?? CorporateContactType.Sales}
          >
            {Object.values(CorporateContactType).map(x => (
              <MenuItem key={x} value={x}>
                <EnumValueDisplay value={x} />
              </MenuItem>
            ))}
          </FormSelect>
        </Grid>
        <Grid item xs={12}>
          <ErrorDisplay error={error} />
          <SubmitButton
            variant="contained"
            color="secondary"
            fullWidth
            pending={status === FormStatus.Pending}
            disabled={status === FormStatus.Pending}
            data-testid="addDelegateSubmitButton"
          >
            Submit
          </SubmitButton>
        </Grid>
      </Grid>
    </FormHub>
  );
}
