import { QueryEntity } from '@datorama/akita';
import { switchMap } from 'rxjs/operators';
import { InvoiceStatusState, invoiceStatusStore } from './invoice-status.store';
import { InvoiceSort, InvoiceStatus } from './invoice-status.model';

function sortBy(a: InvoiceStatus, b: InvoiceStatus, { ui: { sortBy } }: InvoiceStatusState) {
  switch (sortBy) {
    case InvoiceSort.CorporateAccount:
      return (a.corporateAccount ?? '') > (b.corporateAccount ?? '') ? 1 : -1;
    case InvoiceSort.Location:
      return (a.location ?? '') > (b.location ?? '') ? 1 : -1;
    case InvoiceSort.InvoiceNumber:
    default:
      return (a.invoiceNumber ?? '') > (b.invoiceNumber ?? '') ? 1 : -1;
  }
}

export class InvoiceStatusQuery extends QueryEntity<InvoiceStatusState> {
  corporateAccounts = this.selectAll().pipe(
    switchMap(a =>
      this.selectMany(
        a.map(s => s.id),
        s => ({ accountId: s.corporateAccountId ?? '', accountName: s.corporateAccount ?? '' })
      )
    )
  );

  invoiceStatuses = this.select(({ ui }) => ui).pipe(
    switchMap(ui =>
      this.selectAll({
        filterBy: [
          x => !ui.corporateAccountId || ui.corporateAccountId === x.corporateAccountId,
          x => !ui.invoiceRunId || ui.invoiceRunId === x.invoiceRunId,
          x =>
            !ui.createdStartDate ||
            (!!x.createdDate && ui.createdStartDate.getTime() <= Date.parse(x.createdDate)),
          x =>
            !ui.createdEndDate ||
            (!!x.createdDate && ui.createdEndDate.getTime() >= Date.parse(x.createdDate)),
          x =>
            !ui.createdStartDate ||
            (!!x.createdDate && ui.createdStartDate.getTime() <= Date.parse(x.createdDate)),
          x => !ui.status || (!!x.createInvoiceStatus && x.createInvoiceStatus === ui.status),
        ],
        sortBy,
      })
    )
  );

  loading = this.selectLoading();
}
export const invoiceStatusQuery = new InvoiceStatusQuery(invoiceStatusStore);
