import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { GetInvoiceRunRequest, InvoiceRun } from './invoice-run.model';
import { PaginationState } from '@lib/state';

export interface InvoiceRunState
  extends EntityState<InvoiceRun, InvoiceRun['id']>,
    ActiveState<InvoiceRun['id']> {
  ui: GetInvoiceRunRequest;
  pagination: PaginationState;
}

@StoreConfig({ name: 'invoice-run', resettable: true })
export class InvoiceRunStore extends EntityStore<InvoiceRunState> {
  constructor() {
    super({
      pagination: {
        isDone: true,
        continuationToken: undefined,
      },
      loading: false,
    });
  }
}

export const invoiceRunStore = new InvoiceRunStore();
