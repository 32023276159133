import {
  Reservations,
  HousekeepingPolicy,
  ReservationPolicy,
  Reservation,
  getDelegate,
  GuestProfile,
} from '@lib/state';
import { formatIncomingPolicy } from '../property-configuration';

export type CorporateDelegate = Reservations.CorporateAccountDelegateModel;
export type CorporateZonePreference = Reservations.CorporateZonePreferenceModel;

export type CorporateAccount = Reservations.CorporateAccountModel & {
  delegates?: Array<CorporateDelegate>;
  zones?: Array<CorporateZonePreference>;
};

export type CostCenterFieldConfiguration = Reservations.CostCenterFieldConfiguration;

export type BillingFrequencyType = Reservations.BillingFrequencyType;
export const BillingFrequencyType = Reservations.BillingFrequencyType;

export type HousekeepingPolicyType = {
  title: 'Housekeeping';
  policy?: HousekeepingPolicy;
};

export type ReservationPolicyType = {
  title: 'Reservation';
  policy?: ReservationPolicy;
};

export type PolicyType = ReservationPolicyType | HousekeepingPolicyType;

export function formatReservationPolicy(account: CorporateAccount): CorporateAccount {
  return { ...account, reservation: formatIncomingPolicy(account.reservation) };
}

export function getCoporateDelegate(reservation: Reservation): CorporateDelegate | undefined {
  if (!reservation?.affiliation) return undefined;

  const delegate = getDelegate(reservation);

  if (!delegate) return undefined;

  return {
    corporateAccountId: reservation.affiliation.corporateAccountId!,
    userId: delegate.userId!,
    contact: delegate.contact,
    name: delegate.name,
  };
}

export function toGuestProfile(delegate?: CorporateDelegate): GuestProfile | undefined {
  return !!delegate
    ? ({
        userId: delegate.userId,
        name: delegate.name,
        contact: delegate.contact,
      } as GuestProfile)
    : undefined;
}
