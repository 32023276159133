import { DataTable, EnumValueDisplay, IDataTableColumn } from '@lib/common';
import { Box, Typography } from '@material-ui/core';
import { InvoiceStatus } from 'app/state';
import React from 'react';

interface Props {
  statuses: Array<InvoiceStatus>;
}

function renderCell(value?: string | null) {
  return <Typography>{value}</Typography>;
}

const columns: Array<IDataTableColumn<InvoiceStatus>> = [
  {
    title: 'Invoice No.',
    valueFactory: status => renderCell(status.invoiceNumber),
  },
  {
    title: 'Status',
    valueFactory: status => <EnumValueDisplay value={status.createInvoiceStatus} />,
  },
  {
    title: 'Status Message',
    valueFactory: status => renderCell(status.statusMessage),
  },
  {
    title: 'Corporate Account',
    valueFactory: status => renderCell(status.corporateAccount),
  },
  {
    title: 'Location',
    valueFactory: status => renderCell(status.location),
  },
  {
    title: 'Cost Center 1',
    valueFactory: status => renderCell(status.costCenter1),
  },
  {
    title: 'Cost Center 2',
    valueFactory: status => renderCell(status.costCenter2),
  },
];

export function InvoiceStatusList({ statuses }: Props) {
  return (
    <Box mt={2}>
      <DataTable
        items={statuses}
        columns={columns}
        getItemKey={status => status.id ?? ''}
        loading={statuses.length === 0}
      />
    </Box>
  );
}
