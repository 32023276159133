import { Reservations } from '@lib/state';

export type CreateInvoiceStatus = Reservations.CreateInvoiceStatus;
export const CreateInvoiceStatus = Reservations.CreateInvoiceStatus;

export type InvoiceStatus = Reservations.InvoiceStatusModel;

export enum InvoiceSort {
  CorporateAccount = 'CorporateAccount',
  InvoiceNumber = 'InvoiceNumber',
  Location = 'Location',
}

export interface InvoiceStatusFilter {
  createdStartDate?: Date;
  createdEndDate?: Date;
  status?: CreateInvoiceStatus;
  invoiceRunId?: string;
  corporateAccountId?: string;
  customerId?: string;
  sortBy?: InvoiceSort;
}
