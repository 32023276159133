import {
  DataTable,
  DateDisplay,
  EnumValueDisplay,
  IDataTableColumn,
  ShowMoreButton,
} from '@lib/common';
import { Box, Typography } from '@material-ui/core';
import { InvoiceRun } from 'app/state';
import React from 'react';
import { useHistory } from 'react-router-dom';

interface Props {
  runs: Array<InvoiceRun>;
  loading: boolean;
  isDone: boolean;
  loadMore: () => void;
}

function renderCell(value?: string | null | number) {
  return <Typography>{value}</Typography>;
}

const columns: Array<IDataTableColumn<InvoiceRun>> = [
  {
    title: 'Created Date',
    valueFactory: run => (
      <DateDisplay date={new Date(run.createdDate ?? '')} dateFormat="M/d/yy h:mm a" />
    ),
  },
  {
    title: 'Start Date',
    valueFactory: run => (
      <DateDisplay date={new Date(run.invoiceStartDate ?? '')} dateFormat="M/d/yy" />
    ),
  },
  {
    title: 'End Date',
    valueFactory: run => (
      <DateDisplay date={new Date(run.invoiceEndDate ?? '')} dateFormat="M/d/yy" />
    ),
  },
  {
    title: 'Invoice Type',
    valueFactory: run => <EnumValueDisplay value={run.invoiceType} />,
  },
  {
    title: 'Billing Frequency',
    valueFactory: run => <EnumValueDisplay value={run.billingFrequencyType} />,
  },
  {
    title: 'Invoices Created',
    valueFactory: run => renderCell(run.invoicesCreated),
  },
  {
    title: 'Invoice Errors',
    valueFactory: run => renderCell(run.invoiceErrors),
  },
  {
    title: '',
    valueFactory: run => renderCell(run.isDraft ? 'Draft' : undefined),
  },
];

export function InvoiceRunList({ runs, loading, isDone, loadMore }: Props) {
  const history = useHistory();
  const onRowClick = (r: InvoiceRun) => {
    const path = `corporate-accounts/run/${r.id}`;
    history.push(path);
  };

  return (
    <Box mt={2}>
      <DataTable
        items={runs}
        columns={columns}
        getItemKey={r => r.id ?? ''}
        loading={loading}
        onRowClick={onRowClick}
      >
        <ShowMoreButton isDone={isDone} loadMore={loadMore} />
      </DataTable>
    </Box>
  );
}
