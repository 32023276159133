import { FormSelect, EnumValueDisplay } from '@lib/common';
import { Grid, MenuItem } from '@material-ui/core';
import { FilterSection } from 'app/shared';
import { CreateInvoiceStatus, InvoiceSort, InvoiceStatusFilter } from 'app/state';
import React, { useEffect } from 'react';
import { FormContext, useForm } from 'react-hook-form';

interface Props {
  onChange: (filters: InvoiceStatusFilter) => void;
  corporateAccounts: Array<AccountOption>;
}

interface AccountOption {
  accountId: string;
  accountName: string;
}

export function InvoiceStatusFilterForm({ onChange, corporateAccounts }: Props) {
  const form = useForm<InvoiceStatusFilter>({
    defaultValues: {
      sortBy: InvoiceSort.InvoiceNumber,
      corporateAccountId: '',
    },
  });

  const { corporateAccountId, status, sortBy } = form.watch({ nest: true });

  useEffect(() => {
    if (onChange !== null) onChange({ corporateAccountId, status, sortBy });
  }, [onChange, corporateAccountId, status, sortBy]);

  return (
    <FilterSection>
      <FormContext {...form}>
        <Grid container spacing={2} style={{ padding: '0 1rem 1rem 1rem', marginTop: '1rem' }}>
          <Grid item xs={3}>
            <FormSelect
              name="sortBy"
              label="Sort By"
              fullWidth
              defaultValue={InvoiceSort.InvoiceNumber}
            >
              {Object.keys(InvoiceSort).map(r => (
                <MenuItem key={r} value={r}>
                  <EnumValueDisplay value={r} />
                </MenuItem>
              ))}
            </FormSelect>
          </Grid>
          <Grid item xs={3}>
            <FormSelect
              name="corporateAccountId"
              label="Corporate Account"
              fullWidth
              defaultValue=""
            >
              <MenuItem key="None" value="">
                All
              </MenuItem>
              {corporateAccounts.map(ca => (
                <MenuItem key={ca.accountId} value={ca.accountId}>
                  {ca.accountName}
                </MenuItem>
              ))}
            </FormSelect>
          </Grid>
          <Grid item xs={3}>
            <FormSelect name="status" label="Status" fullWidth>
              <MenuItem key="None">All</MenuItem>
              {Object.keys(CreateInvoiceStatus).map(s => (
                <MenuItem key={s} value={s}>
                  <EnumValueDisplay value={s} />
                </MenuItem>
              ))}
            </FormSelect>
          </Grid>
        </Grid>
      </FormContext>
    </FilterSection>
  );
}
