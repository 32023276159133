import { useObservable } from '@lib/common';
import { makeStyles, createStyles } from '@material-ui/core';
import {
  employeeQuery,
  employeeService,
  GetInvoiceRunRequest,
  invoiceRunQuery,
  invoiceRunService,
  InvoiceStatusFilter,
  invoiceStatusQuery,
  invoiceStatusService,
} from 'app/state';
import { useEffect } from 'react';

export function usePollInvoiceStatuses(filters?: InvoiceStatusFilter, continuePolling?: boolean) {
  useEffect(() => {
    return invoiceStatusService.pollInvoiceStatuses({ ...filters }, continuePolling);
  }, [filters, continuePolling]);

  return {
    statuses: useObservable(invoiceStatusQuery.invoiceStatuses),
    corporateAccounts: useObservable(invoiceStatusQuery.corporateAccounts),
  };
}

export function useGetInvoiceStatuses(filters?: InvoiceStatusFilter) {
  useEffect(() => {
    invoiceStatusService.getInvoiceStatuses({ ...filters });
  }, [filters]);

  return {
    statuses: useObservable(invoiceStatusQuery.invoiceStatuses),
    corporateAccounts: useObservable(invoiceStatusQuery.corporateAccounts),
    loading: useObservable(invoiceStatusQuery.loading),
  };
}

export function usePollInvoiceRunById(id: string, continuePolling?: boolean) {
  useEffect(() => {
    return invoiceRunService.getInvoiceRunById(id, continuePolling);
  }, [id, continuePolling]);

  return {
    run: useObservable(invoiceRunQuery.invoiceRun),
  };
}

export function useGetInvoiceRuns(request?: GetInvoiceRunRequest) {
  useEffect(() => {
    invoiceRunService.getInvoiceRuns({ ...request }, {});
  }, [request]);

  const { continuationToken, isDone } = useObservable(invoiceRunQuery.pagination);

  return {
    runs: useObservable(invoiceRunQuery.invoiceRuns),
    loading: useObservable(invoiceRunQuery.loading),
    continuationToken,
    isDone,
  };
}

export function useGetEmployees(propertyId: string) {
  useEffect(() => {
    employeeService.getEmployees(propertyId);
  }, [propertyId]);

  return {
    employees: useObservable(employeeQuery.employees),
  };
}

export const useStyles = makeStyles(theme =>
  createStyles({
    runDetailText: {
      fontSize: '1.5em',
    },
    runDetailCells: {
      display: 'flex',
    },
  })
);
