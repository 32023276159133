import { Loading, Section, useObservable, usePageTitle } from '@lib/common';
import { Container, Paper } from '@material-ui/core';
import { BreadcrumbBackButton } from 'app/shared';
import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useGetEmployees, usePollInvoiceRunById, usePollInvoiceStatuses } from '../hooks';
import { InvoiceRunDetails, InvoiceStatusFilterForm, InvoiceStatusList } from '../components';
import { CreateInvoiceStatus, InvoiceRunStatus, InvoiceStatusFilter } from 'app/state';
import { sessionQuery } from '@lib/state';

export function InvoiceRunDetailsPage({
  match,
}: RouteComponentProps<{
  runId: string;
}>) {
  usePageTitle('Invoice Run Details');
  const runId = match.params.runId;
  const propertyId = useObservable(sessionQuery.propertyId, 'async');
  const [filters, setFilters] = useState<InvoiceStatusFilter>({ invoiceRunId: runId });
  const [continuePolling, setContinuePolling] = useState(true);
  const { run } = usePollInvoiceRunById(runId, continuePolling);
  const { employees } = useGetEmployees(propertyId ?? '');
  const { statuses, corporateAccounts } = usePollInvoiceStatuses(filters, continuePolling);

  const onFilterChange = useCallback(
    (filters: InvoiceStatusFilter) => {
      setFilters({ ...filters, invoiceRunId: runId });
    },
    [runId, setFilters]
  );

  useEffect(() => {
    if (
      run &&
      run.runStatus === InvoiceRunStatus.Complete &&
      (run.invoicesStatus === CreateInvoiceStatus.Success ||
        run.invoicesStatus === CreateInvoiceStatus.Failed ||
        run.invoicesStatus === CreateInvoiceStatus.SuccessWithWarnings)
    )
      setContinuePolling(false);
  }, [run]);

  return (
    <Container maxWidth="lg" data-testid="invoiceRunDetailsPage">
      <BreadcrumbBackButton destination="/corporate-accounts" />
      <Paper elevation={2} style={{ padding: '.5rem 1.5rem' }}>
        <Section style={{ marginTop: 0 }} variant="default">
          {run ? (
            <>
              <InvoiceRunDetails run={run} employees={employees} />
              {corporateAccounts && (
                <Section>
                  <InvoiceStatusFilterForm
                    onChange={onFilterChange}
                    corporateAccounts={corporateAccounts}
                  />
                  <InvoiceStatusList statuses={statuses ?? []} />
                </Section>
              )}
            </>
          ) : (
            <Loading />
          )}
        </Section>
      </Paper>
    </Container>
  );
}
