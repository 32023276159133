import { Reservations } from '@lib/state';
import { BillingFrequencyType } from '../corporate-account';
import { CreateInvoiceStatus, InvoiceType } from '..';

export type InvoiceRun = Reservations.InvoiceRunModel;

export type InvoiceRunStatus = Reservations.InvoiceRunStatus;
export const InvoiceRunStatus = Reservations.InvoiceRunStatus;

export type GetInvoiceRunRequest = {
  createdStartDate?: Date;
  createdEndDate?: Date;
  invoiceStartDate?: Date;
  invoiceEndDate?: Date;
  status?: CreateInvoiceStatus;
  isDraft?: boolean;
  billingFrequencyType?: BillingFrequencyType;
  invoiceType?: InvoiceType;
  sortBy?: InvoiceRunSort;
};

export enum InvoiceRunSort {
  StartDate = 'StartDate',
  InvoiceType = 'InvoiceType',
  BillingFrequency = 'BillingFrequency',
}
