import { AppColors, DateDisplay, EnumValueDisplay } from '@lib/common';
import { Box, Grid, Typography } from '@material-ui/core';
import { useStyles } from 'app/corporate-account/hooks';
import { Employee, InvoiceRun } from 'app/state';
import React, { useMemo } from 'react';

interface Props {
  run: InvoiceRun;
  employees: Array<Employee>;
}

export function InvoiceRunDetails({ run, employees }: Props) {
  const employee = useMemo(() => employees.find(e => e.userId === run.createdById), [
    run,
    employees,
  ]);
  const styles = useStyles();
  return (
    <Box>
      <Grid container justifyContent="space-between" style={{ marginTop: 0 }} spacing={1}>
        <Grid item sm={6} className={styles.runDetailCells}>
          <Typography variant="h1">Invoice run for:&ensp;</Typography>
          <DateDisplay date={new Date(run.createdDate ?? '')} dateFormat="M/d/yy" variant="h1" />
        </Grid>
        {run.isDraft && (
          <Grid item sm={6} className={styles.runDetailCells} justifyContent="flex-end">
            <Typography
              variant="h4"
              style={{
                display: 'flex',
                textAlign: 'right',
                backgroundColor: AppColors.DarkBlue,
                color: AppColors.White,
                padding: '.5rem 1rem',
                borderRadius: '1rem',
              }}
            >
              Draft
            </Typography>
          </Grid>
        )}

        <Grid item sm={12} className={styles.runDetailCells}>
          <div className={styles.runDetailCells}>
            <Typography variant="h2">Status:&ensp;</Typography>
            <EnumValueDisplay value={run.runStatus} variant="h2" />
          </div>
        </Grid>
        <Grid item sm={6} className={styles.runDetailCells}>
          <Typography className={styles.runDetailText}>Start Date:&ensp;</Typography>{' '}
          <DateDisplay
            className={styles.runDetailText}
            date={new Date(run.invoiceStartDate ?? '')}
            dateFormat="M/d/yy"
          />
        </Grid>
        <Grid item sm={6} className={styles.runDetailCells}>
          <Typography className={styles.runDetailText}>End Date:&ensp;</Typography>
          <DateDisplay
            className={styles.runDetailText}
            date={new Date(run.invoiceEndDate ?? '')}
            dateFormat="M/d/yy"
          />
        </Grid>
        <Grid item sm={6} className={styles.runDetailCells}>
          <Typography className={styles.runDetailText}>Type:&ensp;</Typography>
          <EnumValueDisplay className={styles.runDetailText} value={run.invoiceType} />
        </Grid>
        <Grid item sm={6} className={styles.runDetailCells}>
          <Typography className={styles.runDetailText}>Billing Frequency:&ensp;</Typography>
          {run.billingFrequencyType ? (
            <EnumValueDisplay className={styles.runDetailText} value={run.billingFrequencyType} />
          ) : (
            <Typography className={styles.runDetailText}>N/A</Typography>
          )}
        </Grid>
        <Grid item sm={6} className={styles.runDetailCells}>
          <Typography className={styles.runDetailText}>
            Created By: {employee?.name.first} {employee?.name.last}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
