import React, { useCallback, useEffect, useMemo } from 'react';
import { Box, Link, Typography } from '@material-ui/core';
import {
  RemoveButton,
  ResendButton,
  Section,
  useFormEvents,
  useHasRole,
  ShowMoreButton,
  DataTable,
  EnumValueDisplay,
} from '@lib/common';
import {
  CorporateAccount,
  CorporateInvoice,
  CorporateInvoiceForms,
  corporateInvoiceService,
  InvoiceStatusFilter,
} from 'app/state';
import { CreateInvoice } from './create-invoice';
import { Accounting, FormStatus } from '@lib/state';
import { CorporateAccountFormFields } from './corporate-account-form';
import { CreateInvoiceStatus } from '@lib/state/api/generated/reservations';
import { useGetInvoiceStatuses } from '../hooks';

export interface Props {
  account: CorporateAccount;
  invoices: CorporateInvoice[];
  formStatus: FormStatus;
  formError?: Error;
  resetFormUI: () => void;
  onSubmit: (values: CorporateAccountFormFields) => void;
  loadMore: () => void;
  isDone: boolean;
}

export const CorporateAccountInvoiceList: React.FC<Props> = ({
  account,
  invoices,
  loadMore,
  isDone,
}) => {
  const filters = useMemo<InvoiceStatusFilter>(() => ({ corporateAccountId: account.id }), [
    account,
  ]);
  const { statuses } = useGetInvoiceStatuses(filters);
  const [{ status, error }, resetStatus] = useFormEvents(CorporateInvoiceForms.Remove);
  const [{ status: resendStatus, error: resendError }, resetResendStatus] = useFormEvents(
    CorporateInvoiceForms.Resend
  );
  const accountingRole = useHasRole(Accounting);

  const removeInvoice = useCallback(
    (invoice: CorporateInvoice) => corporateInvoiceService.removeInvoice(invoice),
    []
  );
  const resendInvoice = useCallback(
    (invoice: CorporateInvoice) => corporateInvoiceService.resendInvoice(invoice),
    []
  );

  const createInvoiceErrorStatus = useMemo(() => {
    if (statuses && statuses.length > 0) {
      const result = statuses.sort(
        (a, b) => Date.parse(a.createdDate ?? '') - Date.parse(b.createdDate ?? '')
      )[0];
      if (result) {
        if (result.createInvoiceStatus === CreateInvoiceStatus.Failed) {
          return new Error(result.statusMessage ?? '');
        }
      }
    }
    return undefined;
  }, [statuses]);

  // when the invoice list changes, reset so that the remove button can be used again
  useEffect(() => resetStatus(), [invoices, resetStatus]);

  const renderResendButton = useCallback(
    x => (
      <ResendButton
        onConfirm={() => resendInvoice(x)}
        onSuccess={() => resetResendStatus()}
        status={resendStatus}
        error={resendError}
        confirmView={
          <Typography>Are you sure you want to resend invoice {x.invoiceNumber}?</Typography>
        }
      />
    ),
    [resendInvoice, resendStatus, resendError, resetResendStatus]
  );

  const renderRemoveButton = useCallback(
    x => (
      <RemoveButton
        onConfirm={() => removeInvoice(x)}
        status={status}
        error={error}
        confirmView={
          <Typography>Are you sure you want to remove invoice {x.invoiceNumber}?</Typography>
        }
      />
    ),
    [removeInvoice, status, error]
  );

  return (
    <Section title="Invoices" textAlign="left">
      <Box my="1rem" display="flex" justifyContent="flex-end" flexDirection="row">
        {account && (
          <CreateInvoice
            account={account}
            createMultipleInvoices={false}
            createInvoiceErrorStatus={createInvoiceErrorStatus}
          />
        )}
      </Box>
      <DataTable
        items={invoices}
        children={<ShowMoreButton isDone={isDone} loadMore={loadMore} />}
        getItemKey={x => x.id}
        columns={[
          {
            title: 'Invoice No',
            valueFactory: x => (
              <Link
                href="#"
                onClick={(e: React.MouseEvent) => {
                  e.preventDefault();
                  corporateInvoiceService.downloadInvoice(x);
                }}
              >
                {x.invoiceNumber}
              </Link>
            ),
          },
          {
            title: 'Type',
            valueFactory: x => <EnumValueDisplay value={x.type} />,
          },
          {
            title: 'Location',
            valueFactory: x => x.location ?? '--',
          },
          {
            title: 'Cost Center 1',
            valueFactory: x => x.costCenter1 ?? '--',
          },
          {
            title: 'Cost Center 2',
            valueFactory: x => x.costCenter2 ?? '--',
          },
          {
            title: 'Generated Date',
            valueFactory: x => x.generatedDate,
          },
          {
            title: 'Start Date',
            valueFactory: x => x.startDate,
          },
          {
            title: 'End Date',
            valueFactory: x => x.endDate,
          },
          {
            title: '',
            align: 'right',
            valueFactory: x => renderRemoveButton(x),
          },

          {
            title: ' ',
            align: 'right',
            hidden: !accountingRole,
            valueFactory: x => renderResendButton(x),
          },
        ]}
      />
    </Section>
  );
};
