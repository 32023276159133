import { FormSelect, EnumValueDisplay, FormDatePicker, FormCheckbox } from '@lib/common';
import { Grid, MenuItem } from '@material-ui/core';
import { FilterSection } from 'app/shared';
import {
  BillingFrequencyType,
  CreateInvoiceStatus,
  GetInvoiceRunRequest,
  InvoiceRunSort,
  InvoiceType,
} from 'app/state';
import React, { useEffect } from 'react';
import { useForm, FormContext } from 'react-hook-form';

interface Props {
  onChange: (request: GetInvoiceRunRequest) => void;
}

export function InvoiceRunFilterForm({ onChange }: Props) {
  const form = useForm<GetInvoiceRunRequest>();

  const {
    createdStartDate,
    createdEndDate,
    invoiceType,
    isDraft,
    billingFrequencyType,
    status,
    sortBy,
  } = form.watch({ nest: true });

  useEffect(() => {
    if (onChange !== null)
      onChange({
        createdStartDate,
        createdEndDate,
        invoiceType,
        isDraft,
        billingFrequencyType,
        status,
        sortBy,
      });
  }, [
    onChange,
    createdStartDate,
    createdEndDate,
    invoiceType,
    isDraft,
    billingFrequencyType,
    status,
    sortBy,
  ]);

  return (
    <FilterSection>
      <FormContext {...form}>
        <Grid container spacing={2} style={{ padding: '0 1rem 1rem 1rem', marginTop: '1rem' }}>
          <Grid item xs={3}>
            <FormSelect
              name="sortBy"
              label="Sort By"
              fullWidth
              defaultValue={InvoiceRunSort.StartDate}
            >
              {Object.keys(InvoiceRunSort).map(r => (
                <MenuItem key={r} value={r}>
                  <EnumValueDisplay value={r} />
                </MenuItem>
              ))}
            </FormSelect>
          </Grid>
          <Grid item xs={3}>
            <FormSelect name="status" label="Status" fullWidth defaultValue="">
              <MenuItem key="any" value="">
                All
              </MenuItem>
              {Object.keys(CreateInvoiceStatus).map(s => (
                <MenuItem key={s} value={s}>
                  <EnumValueDisplay value={s} />
                </MenuItem>
              ))}
            </FormSelect>
          </Grid>
          <Grid item xs={3}>
            <FormDatePicker
              name={'createdStartDate'}
              label="Start Date"
              defaultValue=""
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <FormDatePicker name={'createdEndDate'} label="End Date" defaultValue="" fullWidth />
          </Grid>
          <Grid item xs={3}>
            <FormSelect name="invoiceType" label="Invoice Type" defaultValue="" fullWidth>
              <MenuItem key="any" value="">
                All
              </MenuItem>
              {Object.keys(InvoiceType).map(t => (
                <MenuItem key={t} value={t}>
                  <EnumValueDisplay value={t} />
                </MenuItem>
              ))}
            </FormSelect>
          </Grid>
          <Grid item xs={3}>
            <FormSelect
              name="billingFrequencyType"
              label="Billing Frequency"
              defaultValue=""
              fullWidth
            >
              <MenuItem key="any" value="">
                All
              </MenuItem>
              {Object.keys(BillingFrequencyType).map(b => (
                <MenuItem key={b} value={b}>
                  <EnumValueDisplay value={b} />
                </MenuItem>
              ))}
            </FormSelect>
          </Grid>
          <Grid item xs={3}>
            <FormCheckbox name={'isDraft'} label="Is Draft" />
          </Grid>
        </Grid>
      </FormContext>
    </FilterSection>
  );
}
