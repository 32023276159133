import { QueryEntity } from '@datorama/akita';
import { InvoiceRunState, invoiceRunStore } from './invoice-run.store';
import { InvoiceRun, InvoiceRunSort } from './invoice-run.model';
import { switchMap } from 'rxjs/operators';
import { BillingFrequencyType } from '../corporate-account';
import { InvoiceType } from '../corporate-invoices';

const BillingFrequencyOrder = Object.values(BillingFrequencyType);
const InvoiceTypeOrder = Object.values(InvoiceType);

function sortBy(a: InvoiceRun, b: InvoiceRun, { ui: { sortBy } }: InvoiceRunState) {
  switch (sortBy) {
    case InvoiceRunSort.BillingFrequency:
      return (
        (a.billingFrequencyType ? BillingFrequencyOrder.indexOf(a.billingFrequencyType) : 0) -
        (b.billingFrequencyType ? BillingFrequencyOrder.indexOf(b.billingFrequencyType) : 0)
      );
    case InvoiceRunSort.InvoiceType:
      return (
        (a.invoiceType ? InvoiceTypeOrder.indexOf(a.invoiceType) : 0) -
        (b.invoiceType ? InvoiceTypeOrder.indexOf(b.invoiceType) : 0)
      );
    case InvoiceRunSort.StartDate:
    default:
      return (
        (a.invoiceStartDate ? Date.parse(a.invoiceStartDate) : 0) -
        (b.invoiceStartDate ? Date.parse(b.invoiceStartDate) : 0)
      );
  }
}

export class InvoiceRunQuery extends QueryEntity<InvoiceRunState> {
  invoiceRuns = this.select(({ ui }) => ui).pipe(
    switchMap(ui =>
      this.selectAll({
        filterBy: [
          r =>
            !ui.billingFrequencyType ||
            (r.billingFrequencyType !== undefined &&
              r.billingFrequencyType === ui.billingFrequencyType),
          r =>
            !ui.createdStartDate ||
            (!!r.createdDate && Date.parse(r.createdDate) >= ui.createdStartDate.getTime()),
          r =>
            !ui.createdEndDate ||
            (!!r.createdDate && Date.parse(r.createdDate) <= ui.createdEndDate.getTime()),
          r => ui.isDraft !== undefined || r.isDraft === ui.isDraft,
          r => !ui.invoiceType || r.invoiceType === ui.invoiceType,
        ],
        sortBy,
      })
    )
  );

  invoiceRun = this.selectActive();

  pagination = this.select(({ pagination }) => pagination);

  loading = this.selectLoading();
}

export const invoiceRunQuery = new InvoiceRunQuery(invoiceRunStore);
