import React, { useState, useEffect, useMemo } from 'react';
import { Box, Typography } from '@material-ui/core';

import { CorporateAccount, propertyQuery } from '@lib/state';
import {
  Section,
  Link,
  ButtonLink,
  ShowMoreButton,
  useLookup,
  useObservable,
  DataTable,
} from '@lib/common';
import { GridSelectionModel } from '@mui/x-data-grid-pro';
import { Tile, DownloadButton } from 'app/shared';
import {
  CorporateAccountSearchFilter,
  CorporateAccountFilters,
} from './corporate-account-search-filter';
import { CreateInvoice } from './create-invoice';
import {
  BillingFrequencyType,
  corporateAccountService,
  CreateInvoiceStatus,
  InvoiceStatus,
} from 'app/state';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  accounts: Array<CorporateAccount>;
  invoiceStatuses: Array<InvoiceStatus>;
  filters: CorporateAccountFilters;
  isDone: boolean;
  loadMore: () => void;
  onChange: (filters: CorporateAccountFilters) => void;
}

export const CorporateAccountLookup: React.FC<Props> = ({
  accounts,
  invoiceStatuses,
  filters,
  isDone,
  loadMore,
  onChange,
}) => {
  const [invoiceButtonHidden, setInvoiceButtonHidden] = useState(true);
  const [selectedAccs, setSelectedAcc] = useState<CorporateAccount[]>([]);
  const billingFrequencyType = useMemo<BillingFrequencyType | undefined>(
    () =>
      BillingFrequencyType[
        (filters.billingFrequencyType ?? '') as keyof typeof BillingFrequencyType
      ],
    [filters.billingFrequencyType]
  );

  useEffect(() => {
    if (filters.billingFrequencyType !== 'All') {
      setSelectedAcc(accounts);
      setInvoiceButtonHidden(accounts.length === 0);
    } else {
      setSelectedAcc([]);
      setInvoiceButtonHidden(true);
    }
  }, [filters.billingFrequencyType, setSelectedAcc, accounts]);

  const accountLookup = useLookup(accounts, x => x.id);
  const activeProperty = useObservable(propertyQuery.activeProperty, 'async');

  const getCreateInvoiceStatus = (aId: string) => {
    if (!!invoiceStatuses && invoiceStatuses.length > 0) {
      //Get the latest status for an account
      const result = invoiceStatuses
        .filter(s => s.corporateAccountId === aId)
        .sort((a, b) => Date.parse(a.createdDate ?? '') - Date.parse(b.createdDate ?? ''))[0];

      if (result) {
        if (result.createInvoiceStatus === CreateInvoiceStatus.Failed) {
          return (
            <Typography>
              <FontAwesomeIcon icon="times-circle" color="red" /> {' ' + result.statusMessage}
            </Typography>
          );
        } else {
          return (
            <Typography>
              <FontAwesomeIcon icon="check-circle" color="green" />
              {' ' + result.statusMessage}
            </Typography>
          );
        }
      }
    }
    return <Typography></Typography>;
  };

  return (
    <Tile style={{ padding: 0 }}>
      <Box display="flex" justifyContent="flex-end" flexDirection="row" p={0}>
        {!invoiceButtonHidden && (
          <Box mr={2}>
            <CreateInvoice
              account={accounts[0]}
              accounts={selectedAccs}
              createMultipleInvoices={true}
              billingFrequencyType={billingFrequencyType}
            />
          </Box>
        )}
        <Box display="flex" justifyContent="flex-end" flexDirection="row" marginRight={2}>
          <DownloadButton
            onDownload={() =>
              corporateAccountService.exportAllCorporateAccounts(
                accounts[0].customerId,
                activeProperty?.id
              )
            }
          />
        </Box>
        <Box display="flex" justifyContent="flex-end" flexDirection="row">
          <ButtonLink to="/corporate-accounts/add" color="primary" variant="contained">
            Create Corporate Account
          </ButtonLink>
        </Box>
      </Box>
      <CorporateAccountSearchFilter filters={filters} onChange={onChange} />

      {accounts.length > 0 && (
        <Section title="Results" textAlign="left" maxWidth="xl">
          <DataTable
            items={accounts}
            getItemKey={account => account.id}
            checkboxSelection={true}
            children={<ShowMoreButton isDone={isDone} loadMore={loadMore} />}
            onSelectionModelChange={(model: GridSelectionModel) => {
              setSelectedAcc(model.map(x => accountLookup.get(x as string)!));
              setInvoiceButtonHidden(model.length === 0);
            }}
            selectionModel={selectedAccs.map(x => x.id)}
            columns={[
              {
                title: 'Name',
                valueFactory: account => (
                  <Link to={`/corporate-accounts/${account.id}`}>
                    <Typography>{account.name}</Typography>
                  </Link>
                ),
              },
              {
                title: 'Invoice Account',

                valueFactory: account => <Typography>{account.invoiceSubAccount}</Typography>,
              },
              {
                title: 'Billing Frequency',
                colSpan: 0.5,
                valueFactory: account => <Typography>{account.billingFrequencyType}</Typography>,
              },
              {
                title: 'Create Invoice Status',
                valueFactory: account => getCreateInvoiceStatus(account.id),
              },
            ]}
          />
        </Section>
      )}
    </Tile>
  );
};
