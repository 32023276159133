import { EntityState, StoreConfig, EntityStore } from '@datorama/akita';
import { InvoiceStatus, InvoiceStatusFilter } from './invoice-status.model';

export interface InvoiceStatusState extends EntityState<InvoiceStatus, InvoiceStatus['id']> {
  ui: InvoiceStatusFilter;
}

@StoreConfig({ name: 'invoice-status', resettable: true })
export class InvoiceStatusStore extends EntityStore<InvoiceStatusState> {}

export const invoiceStatusStore = new InvoiceStatusStore();
