import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, DialogContent, Grid, MenuItem } from '@material-ui/core';
import { FormStatus, Reservations } from '@lib/state';
import {
  EnumValueDisplay,
  ErrorDisplay,
  FormCheckbox,
  FormDatePicker,
  FormHub,
  FormSelect,
  StyledDialog,
  SubmitButton,
  useFormEvents,
  useObservable,
} from '@lib/common';
import {
  BillingFrequencyType,
  CorporateAccount,
  CorporateInvoiceForms,
  corporateInvoiceService,
  invoiceRunQuery,
  InvoiceType,
} from 'app/state';
import { addYears, parse } from 'date-fns';
import { useHistory } from 'react-router-dom';

interface Props {
  account: CorporateAccount;
  accounts?: Array<CorporateAccount>;
  createMultipleInvoices: boolean;
  createInvoiceErrorStatus?: Error;
  billingFrequencyType?: BillingFrequencyType;
}

interface FormFields {
  startDate: string;
  endDate: string;
  isDraft: boolean;
  invoiceType: InvoiceType;
}

export const CreateInvoice: React.FC<Props> = ({
  account,
  accounts,
  createMultipleInvoices,
  createInvoiceErrorStatus,
  billingFrequencyType,
}) => {
  const [showModal, setShowModal] = useState(false);

  const [{ status, error: formError }, resetUI] = useFormEvents(CorporateInvoiceForms.Create);

  const history = useHistory();
  const run = useObservable(invoiceRunQuery.invoiceRun);

  const [dateError, setDateError] = useState<Error>();

  const error = useMemo(() => formError || dateError, [formError, dateError]);

  useEffect(() => {
    if (status === FormStatus.Success && error === undefined && run) {
      setShowModal(false);
      resetUI();

      const path = `/corporate-accounts/run/${run.id}`;
      history.replace(path);
    }
  }, [status, resetUI, error, run, history]);

  const onSubmit = useCallback(
    ({ startDate, endDate, isDraft, invoiceType }: FormFields) => {
      if (
        parse(startDate, 'yyyy-MM-dd', new Date()) <
        addYears(parse(endDate, 'yyyy-MM-dd', new Date()), -1)
      ) {
        setDateError(new Error('Start date cannot exceed 12 months before end date'));
        return;
      }
      setDateError(undefined);
      if (createMultipleInvoices && accounts !== undefined) {
        corporateInvoiceService.createMultipleInvoices(
          accounts,
          startDate,
          endDate,
          invoiceType,
          isDraft,
          billingFrequencyType
        );
      } else {
        corporateInvoiceService.createInvoice(
          account.id,
          startDate,
          endDate,
          invoiceType,
          isDraft,
          billingFrequencyType
        );
      }
    },
    [account, accounts, createMultipleInvoices, billingFrequencyType, setDateError]
  );

  return (
    <>
      <Button
        color="secondary"
        variant="contained"
        onClick={() => {
          resetUI();
          setShowModal(true);
        }}
      >
        Create Invoice
      </Button>
      <StyledDialog title="Create Invoice" open={showModal} onClose={() => setShowModal(false)}>
        <DialogContent>
          <FormHub<FormFields> onSubmit={onSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormDatePicker
                  name="startDate"
                  label="Start Date"
                  fullWidth
                  validationOptions={{ required: true }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormDatePicker
                  name="endDate"
                  label="End Date"
                  fullWidth
                  validationOptions={{ required: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormSelect
                  name="invoiceType"
                  label="Invoice Type"
                  fullWidth
                  required
                  defaultValue={Reservations.InvoiceType.RoomInvoice}
                >
                  {Object.keys(InvoiceType).map(t => (
                    <MenuItem value={t} key={t}>
                      <EnumValueDisplay value={t} />
                    </MenuItem>
                  ))}
                </FormSelect>
              </Grid>
              <Grid item xs={12}>
                <FormCheckbox name="isDraft" label="Is Draft" fullWidth />
              </Grid>
              <Grid item xs={12}>
                <ErrorDisplay error={error ?? createInvoiceErrorStatus} />
                <SubmitButton
                  variant="contained"
                  color="secondary"
                  fullWidth
                  pending={status === FormStatus.Pending}
                  disabled={status === FormStatus.Pending}
                >
                  Create
                </SubmitButton>
              </Grid>
            </Grid>
          </FormHub>
        </DialogContent>
      </StyledDialog>
    </>
  );
};
