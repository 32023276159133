import { CorporateAccountApi } from '@lib/state/api/generated/reservations';
import { invoiceRunStore, InvoiceRunStore } from './invoice-run.store';
import { defer, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { GetInvoiceRunRequest } from './invoice-run.model';
import { applyTransaction, setLoading } from '@datorama/akita';
import { PaginatedQuery, PaginationState, pollUntil, unsubscribeCallback } from '@lib/state';

export class InvoiceRunService {
  constructor(private api: CorporateAccountApi, private store: InvoiceRunStore) {}

  getInvoiceRuns(
    {
      createdStartDate,
      createdEndDate,
      invoiceStartDate,
      invoiceEndDate,
      status,
      isDraft,
      billingFrequencyType,
      invoiceType,
      sortBy,
    }: GetInvoiceRunRequest,
    { continuationToken, limit }: PaginatedQuery
  ) {
    from(
      this.api.corporateaccountInvoiceRunsGet(
        continuationToken ?? undefined,
        limit ?? 20,
        createdStartDate?.toString(),
        createdEndDate?.toString(),
        invoiceStartDate?.toString(),
        invoiceEndDate?.toString(),
        status,
        isDraft,
        billingFrequencyType,
        invoiceType
      )
    )
      .pipe(
        map(data => data.data),
        setLoading(this.store)
      )
      .subscribe(({ data, ...pagination }) =>
        applyTransaction(() => {
          this.store.upsertMany(data);
          this.updatePaginationState(pagination);
          this.updateUI({
            createdStartDate,
            createdEndDate,
            invoiceStartDate,
            invoiceEndDate,
            status,
            isDraft,
            billingFrequencyType,
            invoiceType,
            sortBy,
          });
        })
      );
  }

  getInvoiceRunById(id: string, continuePolling: boolean = false, interval: number = 5000) {
    const sub = defer(() => this.api.corporateaccountInvoiceRunIdGet(id))
      .pipe(
        map(data => data.data.data),
        pollUntil(interval, continuePolling)
      )
      .subscribe(data =>
        applyTransaction(() => {
          this.store.upsert(data.id, data);
          this.store.setActive(id);
        })
      );

    return unsubscribeCallback(sub);
  }

  private updatePaginationState(pagination: PaginationState) {
    this.store.update(() => ({
      pagination,
    }));
  }

  private updateUI(ui: GetInvoiceRunRequest) {
    this.store.update(() => ({ ui }));
  }
}

export const invoiceRunService = new InvoiceRunService(new CorporateAccountApi(), invoiceRunStore);
